import { reactive } from 'vue'

export interface UseLoadingReturnValue<T extends string> {
  isLoading: { value: boolean, name: T | '' }
  startLoading (name: T): void
  resetLoading (): void
}

export function useLoading<T extends string> (): UseLoadingReturnValue<T> {
  const isLoading: UseLoadingReturnValue<T>['isLoading'] = reactive({ value: false, name: '' })

  const startLoading: UseLoadingReturnValue<T>['startLoading'] = (name) => {
    isLoading.value = true
    isLoading.name = name
  }
  const resetLoading: UseLoadingReturnValue<T>['resetLoading'] = () => {
    isLoading.value = false
    isLoading.name = ''
  }

  return {
    isLoading,
    startLoading,
    resetLoading,
  }
}
