import { defineStore } from 'pinia'

import { useApiStore } from '@/stores/api'

import { hotReloadStore } from '@/utils/build'

import type { ValidateUserResponse } from '@policyfly/protobuf'

export const useLoginStore = defineStore({
  id: 'login',

  state: () => ({
    email: '',
    password: '',
    remember_me: false,
    has_authenticator: false,
    has_sms: false,
    targetPath: '', // A path requested before login that should be persisted through the login process and then navigated to when login is complete
  }),

  actions: {
    /**
     * Validates a user using an email/password combination.
     * If valid will return information about the 2FA devices available.
     */
    async validate ({ email, password, remember_me: rememberMe }: { email: string, password: string, remember_me: boolean }): Promise<ValidateUserResponse> {
      const apiStore = useApiStore()
      const validateData = await apiStore.user.validateUser({ email, password })
      this.email = email
      this.password = password
      this.remember_me = rememberMe
      this.has_authenticator = validateData.hasAuthenticator
      this.has_sms = validateData.hasSms
      return validateData
    },
    /**
     * Submits a 2FA code along with the already stored authentication information.
     * If successful will log in the user and store their session details.
     */
    async login ({ token }: { token: string }): Promise<void> {
      const apiStore = useApiStore()
      await apiStore.user.login({
        email: this.email,
        password: this.password,
        otpToken: token,
        rememberMe: this.remember_me,
      })
    },
  },
})

hotReloadStore(useLoginStore)
