import { TransactionType, EventIDType } from '@policyfly/protobuf'

import { InspectorRootNodes, noDataTag } from '@/plugins/devtools/shared'

import type { Devtools } from '@/plugins/devtools/api'
import type { CustomInspectorStateNode } from '@/plugins/devtools/shared'
import type { LuaExecutionEvent } from '@policyfly/protobuf'
import type { CustomInspectorNode } from '@vue/devtools-api'

declare module '@/plugins/devtools/api' {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  interface Devtools {
    /**
     * Initiates refresh of Lua Event logs in the PolicyFly inspector.
     */
    refreshLua(applicationId: string): Promise<void>
  }
}

const rootNodeTitle = 'Lua Data'

interface LuaDataRecord {
  eventId: string
  id: string
  label: string
  nodes: CustomInspectorStateNode[]
}
const luaData: LuaDataRecord[] = []

const fields: (keyof LuaExecutionEvent)[] = ['preLuaOut', 'luaOut', 'postLuaOut', 'error', 'policyState']

export function setupLuaNode (devtools: Devtools): void {
  devtools.refreshLua = async function (applicationId: string) {
    const { events } = await devtools.stores.api?.event.list({
      id: applicationId,
      type: EventIDType.EventIDType_Application,
      include: 'LuaExecutionEvent',
      limit: this.getNumericSetting('luaLogLimit', 10),
      logLevel: 'debug',
    }) ?? { events: [] }

    const newData: LuaDataRecord[] = []
    let needsRefresh = false
    for (const { id, data, created } of events!) {
      if (data?.data.oneofKind !== 'luaExecutionEvent') continue
      const entry = data.data.luaExecutionEvent
      if (!entry || !entry.policyState) continue

      const existingRecordIndex = luaData.findIndex((record) => record.eventId === String(id))
      if (existingRecordIndex > -1) {
        const [record] = luaData.splice(existingRecordIndex, 1)
        newData.push(record)
        continue
      }

      needsRefresh = true
      const txn = entry.policyState.txn
      const createdMs = +(created?.seconds ?? 0) * 1000
      const label = `${id}: ${TransactionType[txn]} [${this.prettyDate(createdMs)}]`

      const nodes: CustomInspectorStateNode[] = []

      for (const field of fields) {
        if (!(field in entry)) continue
        nodes.push({
          key: field,
          editable: false,
          value: {
            _custom: {
              type: null,
              readonly: true,
              value: entry[field],
            },
          },
        })
      }

      newData.push({
        eventId: String(id),
        id: this.buildNodeId(InspectorRootNodes.LUA),
        label,
        nodes,
      })
    }

    luaData.splice(0, luaData.length, ...newData.reverse())
    if (needsRefresh) devtools.refresh()
  }

  devtools.rootNodeGetters.push((payload) => {
    if (payload.filter && !rootNodeTitle.toLowerCase().includes(payload.filter.toLowerCase())) {
      return null
    }

    const label = `${rootNodeTitle} (Limit: ${devtools.api!.getSettings().luaLogLimit})`
    const node: Required<CustomInspectorNode> = {
      id: InspectorRootNodes.LUA,
      label,
      children: luaData.map((record) => {
        return {
          id: record.id,
          label: record.label,
        }
      }),
      tags: [],
    }
    if (!node.children.length) {
      node.tags.push(noDataTag)
    }
    return node
  })

  devtools.stateGetters.push((payload) => {
    if (devtools.getRootNode(payload.nodeId) !== InspectorRootNodes.LUA) return

    const data = luaData.find((record) => record.id === payload.nodeId)
    if (data) {
      payload.state = { data: data.nodes }
    }
  })
}
