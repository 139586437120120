<template>
  <v-dialog v-model="show" width="380" persistent>
    <v-card>
      <v-card-title>Confirm</v-card-title>
      <v-card-text class="my-2" v-text="message" />
      <v-card-actions class="px-4 pb-4">
        <v-row justify="space-between" no-gutters>
          <v-col cols="auto">
            <v-btn theme="dark" color="red" @click="onSelect(true)">
              No
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn theme="dark" color="green" @click="onSelect(false)">
              Yes
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { inject, defineComponent, watch, computed, ref } from 'vue'

import { CONTROLLER_KEY } from '@/plugins/devtools/shared'

export default defineComponent({
  name: 'DevtoolsConfirm',

  setup () {
    const controller = inject(CONTROLLER_KEY)!
    const show = ref(false)
    const confirm = computed(() => controller.confirm)
    watch(() => controller.confirm, (val) => {
      show.value = !!val
    })

    const message = computed(() => confirm.value?.message ?? '')

    function onSelect (selection: boolean): void {
      confirm.value?.resolve(selection)
      show.value = false
    }

    return {
      show,
      message,
      onSelect,
    }
  },
})
</script>
