import { US_STATES } from '@policyfly/utils/constants'
import get from 'lodash-es/get'
import { defineStore } from 'pinia'
import { reactive } from 'vue'

import { useApplicationStore } from '@/stores/application'
import { useAttachmentStore } from '@/stores/attachment'
import { useBridgeStore } from '@/stores/bridge'
import { useDiffStore } from '@/stores/diff'
import { useFeedStore } from '@/stores/feed'
import { useFormBinderStore } from '@/stores/form/binder'
import { useFormEditPremiumStore } from '@/stores/form/editPremium'
import { useFormIssueStore } from '@/stores/form/issue'
import { useLicenseStore } from '@/stores/license'
import { useMetaStore } from '@/stores/meta'
import { usePolicyStore } from '@/stores/policy'
import { useProtobufStore } from '@/stores/protobuf'
import { useRatingStore } from '@/stores/rating'
import { useSettingsStore } from '@/stores/settings'

import Payload from '@/lib/Payload'
import { hotReloadStore } from '@/utils/build'
import { isReinstatement } from '@/utils/policy'
import { nestResponses } from '@/utils/responses'

import type { NestedResponseObject } from '@/lib/Payload'
import type { Wording } from '@/stores/application'
import type { ApplicationCategory } from '@policyfly/types/application'
import type { RoleName } from '@policyfly/types/user'
import type { APIPayloadResponse } from '@policyfly/utils/types'
import type {
  ApplicationQuote,
  ApplicationKind,
  ApplicationQuoteSet,
  Application,
  APIApplication,
  APIApplicationRaw,
} from 'types/application'
import type { Policy } from 'types/policy'

export type AppContextStore = ReturnType<typeof useAppContextStore>

/**
 * @todo Split & namespace
 *
 * This has already been started with application attachments, having a namespace for application, then submodules for each section. It's gonna be hell to break it all up though.
 * Also note that the loadedApplicationData.responses is only used to regenerate nestedResponseObjects from the Feed, if there was a better way to do that then it's redundant
 */
export const useAppContextStore = defineStore({
  id: 'appContext',

  state: () => ({
    loadedApplicationData: {
      id: null as number | null,
      responses: null as APIPayloadResponse[] | null, // flat data loaded from application read endpoint
      quoteSet: null as ApplicationQuote[] | null,
      offerIterators: null as ApplicationQuoteSet['offerIterators'] | null,
      selectedQuote: null as ApplicationQuote | null,
      category: null as ApplicationCategory | null,
      status: null as string | null,
      substatus: null as Application['substatus'],
      external_id: null as number | null | undefined,
      policyId: null as number | null,
      kind: 'APPLICATION' as ApplicationKind,
      effectiveDate: null as string | null,
      expirationDate: null as string | null,
      policyEffectiveDate: null as string | null,
      policyExpirationDate: null as string | null,
      policy: null as Policy | null,
      parentApplicationID: null as number | null,
      childApplicationID: null as number | null,
      lastDescendantID: null as number | null | undefined,
      is_cancellation: false,
      isReclaim: false,
      hasChildren: false,
      agency: null as unknown,
      ownerRole: null as RoleName | null,
      endorsement_iterator: 0 as number | null,
      modified: 0 as string | number,
    },
    isDiffing: false,
    nestedResponseObjects: null as NestedResponseObject | null, // response objects, including k, v and UUID4, nested
    // TODO: This signature logic should be shared with the signature module, which currently only controls the customer signature workflow
    signatureURL: null as string | null,
    signatureClientId: null as string | null,
  }),

  getters: {
    applicationId (): number | null { return this.loadedApplicationData.id },
    agency (): unknown { return get(this, 'loadedApplicationData.agency.name', null) },
    agencyID (): unknown { return get(this, 'loadedApplicationData.agency.id', null) },
    agencyLicense (): unknown {
      const licenses: Record<string, unknown> = get(this, 'loadedApplicationData.agency.licenses', {})
      const licenseState = get(this, 'nestedResponseObjects.state.v', null)
      const stateInfo = US_STATES.find((s) => s.name === licenseState || s.abbreviation === licenseState) || { abbreviation: '' }
      const abbreviation = stateInfo.abbreviation
      return licenses[abbreviation]
    },
    quoteSet (): ApplicationQuote[] | null { return this.loadedApplicationData.quoteSet },
    selectedQuote (): ApplicationQuote | null { return this.loadedApplicationData.selectedQuote },
    selectedQuoteAttachmentLUID (): string | null {
      // TODO: Update to support protobuf selected quote
      if (this.loadedApplicationData.selectedQuote) return this.loadedApplicationData.selectedQuote.attachmentLUID
      return null
    },
    selectedQuotePayload (): Payload | null {
      const { selectedQuote } = this.loadedApplicationData
      if (!selectedQuote) return null
      return Array.isArray(selectedQuote.responses)
        ? Payload.fromResponses(selectedQuote.responses)
        : Payload.fromResponseBlob(selectedQuote.responses)
    },
    nestedResponsePayload (): Payload | null {
      if (!this.nestedResponseObjects) return null
      return Payload.fromResponseObject(this.nestedResponseObjects)
    },
    isCancellation (): boolean { return this.loadedApplicationData.is_cancellation },
    isReclaim (): boolean { return this.loadedApplicationData.isReclaim },
    canStartOver (): boolean { return !this.loadedApplicationData.hasChildren },
    policyId (): number | null { return this.loadedApplicationData.policyId },
    kind (): ApplicationKind { return this.loadedApplicationData.kind },
    status (): string | null { return this.loadedApplicationData.status },
    substatus (): Application['substatus'] { return this.loadedApplicationData.substatus },
    category (): ApplicationCategory | null { return this.loadedApplicationData.category },
    /**
     * Returns `true` if the current application has the kind `'ENDORSEMENT'`.
     * This includes objects such as Cancellations and Reinstatements, if you want a stricter check use `isStrictEndorsement`.
     */
    isEndorsement (): boolean { return this.kind === 'ENDORSEMENT' },
    /**
     * Returns `true` when strictly an endorsement application.
     * Does not include objects that have the kind `'ENDORSEMENT'` but are generally not considered as such, for example Cancellations and Reinstatements.
     */
    isStrictEndorsement (): boolean { return this.kind === 'ENDORSEMENT' && ['END', 'PBE', 'NPB'].includes(this.category!) },
    /**
     * Returns `true` if the currently loaded application is an {@link isReinstatement actionable reinstatement}.
     */
    isReinstatement (): boolean { return isReinstatement(this.loadedApplicationData) },
    isInProgress (): boolean { return this.status !== 'ISSUED' },
  },

  actions: {
    /* eslint-disable camelcase */
    /**
     * This action can be used in components to update all relevant data after receiving an Application object in an API response.
     */
    loadApplicationData (payload: Application | APIApplication | APIApplicationRaw): void {
      const feedStore = useFeedStore()
      const formBinderStore = useFormBinderStore()
      const formEditPremiumStore = useFormEditPremiumStore()
      const formIssueStore = useFormIssueStore()
      const bridgeStore = useBridgeStore()
      const diffStore = useDiffStore()
      const ratingStore = useRatingStore()
      const metaStore = useMetaStore()
      const licenseStore = useLicenseStore()
      const attachmentStore = useAttachmentStore()
      const applicationStore = useApplicationStore()
      const settingsStore = useSettingsStore()

      bridgeStore.$reset()
      const {
        assignee,
        owned_by,
        attachments,
        category,
        status,
        substatus = null,
        computedData,
        quote_set,
        selectedQuote,
        policy,
        // @ts-expect-error: Type is missing on API
        external_id,
        id,
        kind,
        parentID,
        childID,
        lastDescendantID,
        events,
        derivedData,
        effective,
        expiration,
        agency,
        lock_code,
        is_cancellation,
        last_rating,
        license_data,
        owner_role_in_program,
        // @ts-expect-error: Type is missing on API
        vehicleDiffEnglish = null,
        // @ts-expect-error: Type is missing on API
        vehicleDiff = null,
        // @ts-expect-error: Type is missing on API
        namedDriversDiff = null,
        // @ts-expect-error: Type is missing on API
        platesDiff = null,
        endorsement_iterator,
        modified,
        unread_comment_count,
      } = payload
      // If the received application was modified before or at the same time as the loaded application, abandon the load
      // use most recent of last event created date or policy modified if protobuf is enabled
      const modifiedDate = settingsStore.protobuf
        ? Math.max(Date.parse(modified) ?? 0, Date.parse(events?.[0]?.created ?? 0))
        : Date.parse(modified)

      if (modifiedDate <= (this.loadedApplicationData.modified as number)) {
        console.warn('Trying to load an application with an earlier modified date, application will not be loaded')
        return
      }
      this.loadedApplicationData.modified = modifiedDate
      this.loadedApplicationData.id = id
      this.loadedApplicationData.status = status
      this.loadedApplicationData.substatus = substatus
      applicationStore.assignee = assignee as APIApplication['assignee']
      applicationStore.owner = owned_by as APIApplication['owned_by']
      applicationStore.lock_code = lock_code
      applicationStore.wordings = (derivedData?.wordings as Wording[]) || []
      ratingStore.lastRating = last_rating || {}
      metaStore.endorsementMeta.responses = (derivedData?.endorsementMeta as { responses: APIPayloadResponse[] })?.responses || []
      licenseStore.responses = license_data?.responses || []
      // @ts-expect-error: Type is different on API
      attachmentStore.allApplicationAttachments = attachments
      // protobuf application.policy attachments will always be empty
      if (!settingsStore.protobuf) attachmentStore.allPolicyAttachments = policy.attachments
      // used for collection style drivers
      diffStore.appDiffs.namedDriversDiff = namedDriversDiff
      // used for schedule style drivers
      diffStore.appDiffs.driverDiff = namedDriversDiff
      diffStore.appDiffs.vehicleDiff = vehicleDiff
      diffStore.appDiffs.plateDiff = platesDiff
      diffStore.appDiffs.vehicleDiffEnglish = vehicleDiffEnglish
      // Turns out this isn't always safe, so let's check just to be sure.
      if (computedData && computedData.responses) {
        this.loadedApplicationData.responses = computedData.responses
      } else {
        this.loadedApplicationData.responses = null
      }
      this.generateNestedResponseObjects()
      // @ts-expect-error: Type is different on API
      this.loadedApplicationData.quoteSet = quote_set?.quotes ?? null
      // @ts-expect-error: Type is missing on API
      this.loadedApplicationData.offerIterators = quote_set?.offerIterators ?? null
      // @ts-expect-error: Type is different on API
      this.loadedApplicationData.selectedQuote = selectedQuote

      // @ts-expect-error: Type is different on API
      formEditPremiumStore.derivedData.quotes = derivedData?.quote ? [derivedData.quote] : []

      const binderResponses = get(derivedData, 'binder.responses') || []
      formBinderStore.responses = binderResponses

      const issuanceResponses = get(derivedData, 'issuance.responses') || get(derivedData, 'decPage.responses') || []
      formIssueStore.responses = issuanceResponses

      // Load the policy/policy id of the policy associated with this application
      this.loadedApplicationData.policyId = policy.id
      this.loadedApplicationData.policy = policy

      this.loadedApplicationData.kind = kind
      this.loadedApplicationData.category = category

      // Load the agency, making sure that licenses are an array
      this.loadedApplicationData.agency = agency
      // if the associated agency is archived, show a warning
      if (agency?.soft_archived) {
        bridgeStore.warnings = ['The agency associated with this application is archived.']
      }

      // Load the application effective date
      this.loadedApplicationData.effectiveDate = effective
      this.loadedApplicationData.expirationDate = expiration
      this.loadedApplicationData.policyEffectiveDate = policy.effective
      this.loadedApplicationData.policyExpirationDate = policy.expiration

      // load the iterator for an endorsement if exists
      this.loadedApplicationData.endorsement_iterator = endorsement_iterator
      // Load the application's parent application ID (will be present only for endorsements/cancellations)
      this.loadedApplicationData.parentApplicationID = parentID

      // Load the application's child application ID
      this.loadedApplicationData.childApplicationID = childID

      // Load the is_cancellation flag
      this.loadedApplicationData.is_cancellation = is_cancellation

      // Load the last descendant's id for when we need to spawn a new child
      this.loadedApplicationData.lastDescendantID = lastDescendantID

      // Load application events
      feedStore.loadApplicationEvents(events)
      feedStore.loadApplicationCommentCount(unread_comment_count || 0)

      // Load the policy reclaim & has_children flags
      this.loadedApplicationData.isReclaim = !!policy.is_reclaim
      this.loadedApplicationData.hasChildren = !!policy.has_children

      // Load owner information
      this.loadedApplicationData.ownerRole = owner_role_in_program as RoleName | null
      this.loadedApplicationData.external_id = external_id
    },
    /* eslint-enable */
    clearApplicationData (): void {
      const policyStore = usePolicyStore()
      const formBinderStore = useFormBinderStore()
      const formIssueStore = useFormIssueStore()
      const diffStore = useDiffStore()
      const ratingStore = useRatingStore()
      const applicationStore = useApplicationStore()
      const protobufStore = useProtobufStore()
      this.$reset()
      policyStore.$reset()
      applicationStore.$reset()
      ratingStore.$reset()
      formBinderStore.responses = []
      formIssueStore.responses = []
      diffStore.$reset()
      protobufStore.$reset()
    },
    /**
     * Create a structured representation of the application responses that tracks k, v and UUID4 using instances of the ResponseObject class, and load it into state
     */
    generateNestedResponseObjects (): void {
      const objects = nestResponses(this.loadedApplicationData.responses || [], true)
      this.nestedResponseObjects = objects ? reactive(objects) : null
    },
  },
})

hotReloadStore(useAppContextStore)
