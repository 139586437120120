import { InspectorRootNodes, noDataTag } from '@/plugins/devtools/shared'
import { nestResponses } from '@/utils/responses'

import type { Devtools } from '@/plugins/devtools/api'
import type { CustomInspectorStateNode } from '@/plugins/devtools/shared'
import type { APIPayloadResponse } from '@policyfly/utils/types'
import type { CustomInspectorNode } from '@vue/devtools-api'

const rootNodeTitle = 'Application Data'

function createResponseNodes (
  key: string,
  responses: APIPayloadResponse[] | null | undefined,
  noDataText: string,
): CustomInspectorStateNode[] {
  if (responses && responses.length) {
    return [
      {
        key: `${key} (pretty)`,
        editable: false,
        value: nestResponses(responses, false),
      },
      {
        key: `${key} (raw)`,
        editable: false,
        value: nestResponses(responses, true),
      },
    ]
  } else {
    return [
      {
        key,
        editable: false,
        value: noDataText,
      },
    ]
  }
}

export function setupApplicationNode (devtools: Devtools): void {
  devtools.rootNodeGetters.push((payload) => {
    if (payload.filter && !rootNodeTitle.toLowerCase().includes(payload.filter.toLowerCase())) {
      return null
    }

    const node: Required<CustomInspectorNode> = {
      id: InspectorRootNodes.APP,
      label: rootNodeTitle,
      children: [],
      tags: [],
    }
    if (!devtools.stores.appContext?.applicationId) {
      node.tags.push(noDataTag)
    }
    return node
  })

  devtools.stateGetters.push((payload) => {
    if (devtools.getRootNode(payload.nodeId) !== InspectorRootNodes.APP) return

    const appContextStore = devtools.stores.appContext
    if (!appContextStore?.applicationId) return

    const formBinderStore = devtools.stores.formBinder
    const formIssueStore = devtools.stores.formIssue

    payload.state = {
      details: [
        {
          key: 'id',
          editable: false,
          value: appContextStore.loadedApplicationData.id,
        },
        {
          key: 'kind',
          editable: false,
          value: appContextStore.loadedApplicationData.kind,
        },
        {
          key: 'status',
          editable: false,
          value: appContextStore.loadedApplicationData.status,
        },
        {
          key: 'effectiveDate',
          editable: false,
          value: appContextStore.loadedApplicationData.effectiveDate,
        },
        {
          key: 'expirationDate',
          editable: false,
          value: appContextStore.loadedApplicationData.expirationDate,
        },
        {
          key: 'agency',
          editable: false,
          value: appContextStore.agency,
        },
      ],
      formApplication: [
        ...createResponseNodes(
          'data',
          appContextStore.loadedApplicationData.responses,
          'No Data Found',
        ),
      ],
      formQuote: [
        ...createResponseNodes(
          'quoteSet',
          appContextStore.quoteSet?.flatMap((quote, index) => {
            return quote.responses.map((response) => {
              return { ...response, k: `quote${index}.${response.k}` }
            })
          }),
          'No Quote Set Found',
        ),
        ...createResponseNodes(
          'selectedQuote',
          appContextStore.selectedQuote?.responses,
          'No Selected Quote Found',
        ),
      ],
      formBinder: [
        ...createResponseNodes(
          'data',
          formBinderStore?.responses,
          'No Data Found',
        ),
      ],
      formIssue: [
        ...createResponseNodes(
          'data',
          formIssueStore?.responses,
          'No Data Found',
        ),
      ],
    }
  })
}
