<template>
  <div>
    <v-list-item @click="showDialog">
      <template #prepend>
        <v-icon icon="mdi-ghost" />
      </template>
      <v-list-item-title>Ghost Login</v-list-item-title>
    </v-list-item>
    <v-dialog v-model="dialog" width="380">
      <v-card>
        <v-card-title>Ghost Login</v-card-title>
        <v-card-text class="my-2">
          <p>Please confirm the type of user you want to be.</p>
          <p>You will be locked into this program, to change programs first Reset.</p>
          <v-select
            v-model="role"
            :items="roleItems"
            item-title="text"
            label="Role"
            variant="solo"
          />
        </v-card-text>
        <v-card-actions class="px-4 pb-4">
          <v-row justify="space-between" no-gutters>
            <v-col cols="auto">
              <v-btn
                theme="dark"
                color="red"
                :disabled="isLoading.value"
                :loading="isLoading.name === 'reset'"
                @click="onReset"
              >
                Reset
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn
                theme="dark"
                color="green"
                :disabled="isLoading.value"
                :loading="isLoading.name === 'confirm'"
                @click="onConfirm"
              >
                Confirm
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { getSelectedOneofValue } from '@protobuf-ts/runtime'
import { computed, defineComponent, ref } from 'vue'

import { useLoading } from '@/composables/loading'
import { useAuthenticationStore } from '@/stores/authentication'
import { useNotificationStore } from '@/stores/notification'
import { useSettingsStore } from '@/stores/settings'
import { useUserStore } from '@/stores/user'

import type { RoleNameIncSuperuser } from '@policyfly/types/user'
import type { Membership } from 'types/user'

export default defineComponent({
  name: 'DevtoolsActionGhost',

  setup () {
    const { isLoading, startLoading, resetLoading } = useLoading()
    const authenticationStore = useAuthenticationStore()

    const settingsStore = useSettingsStore()
    const role = ref<RoleNameIncSuperuser>('SUPERUSER')
    const roleItems = computed(() => {
      return settingsStore.roles
        .map((r) => ({ text: getSelectedOneofValue(r.name)?.display, value: r.name.oneofKind as RoleNameIncSuperuser }))
        .concat([{ text: 'Superuser', value: 'SUPERUSER' }])
    })
    const notificationStore = useNotificationStore()
    const dialog = ref(false)
    function showDialog (): void {
      if (!authenticationStore.currentProgram) {
        notificationStore.confirm({
          title: 'Ghost Login Not Possible',
          body: 'Must be logged in and within a program',
          persistent: true,
          onlyConfirm: true,
        })
        return
      }
      role.value = authenticationStore.currentProgram.subscription
      dialog.value = true
    }

    const userStore = useUserStore()
    async function onReset (): Promise<void> {
      startLoading('reset')
      try {
        await userStore.load()
        dialog.value = false
      } catch (err) {
        console.error(err)
      } finally {
        resetLoading()
      }
    }

    function onConfirm (): void {
      authenticationStore.programs = [
        { ...authenticationStore.currentProgram, subscription: role.value } as Membership,
      ]
      dialog.value = false
    }

    return {
      dialog,
      showDialog,
      roleItems,
      role,
      isLoading,
      onReset,
      onConfirm,
    }
  },
})
</script>
