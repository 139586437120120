<template>
  <v-menu
    v-if="showFAB"
    v-model="isOpen"
    location="top start"
    :offset="12"
    :close-on-content-click="false"
    transition="slide-y-reverse-transition"
    origin="bottom"
  >
    <template #activator="{ props }">
      <v-btn
        v-bind="props"
        id="devtools-fab-button"
        class="text-white"
        position="fixed"
        location="bottom end"
        color="green"
      >
        <v-fab-transition>
          <v-icon
            theme="dark"
            start
            :icon="isOpen ? 'mdi-close' : 'mdi-plus'"
          />
        </v-fab-transition>
        <span class="text-upper">DEVTOOLS</span>
      </v-btn>
    </template>
    <v-card class="devtools-fab-menu">
      <v-list density="compact">
        <v-list-subheader>DEVTOOLS</v-list-subheader>
        <DevtoolsActionGhost />
        <DevtoolsActionUpload />
      </v-list>
    </v-card>
  </v-menu>
</template>

<script lang="ts">
import { computed, defineComponent, inject, ref } from 'vue'

import DevtoolsActionGhost from './FABActions/DevtoolsActionGhost.vue'
import DevtoolsActionUpload from './FABActions/DevtoolsActionUpload.vue'

import { CONTROLLER_KEY } from '@/plugins/devtools/shared'

export default defineComponent({
  name: 'DevtoolsFAB',

  components: {
    DevtoolsActionGhost,
    DevtoolsActionUpload,
  },

  setup () {
    const controller = inject(CONTROLLER_KEY)!
    const showFAB = computed(() => controller.pluginSettings.showFAB)
    const isOpen = ref(false)

    return {
      showFAB,
      isOpen,
    }
  },
})
</script>

<style lang="sass" scoped>
#devtools-fab-button
  background-color: green !important
  margin-right: 186px
  margin-bottom: 56px
  z-index: 16
  width: 176px
  height: 52px
  border-radius: 26px
  letter-spacing: 2.5px
  font-size: 16px
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.24)
  transition: all 0.3s ease 0s
  &:hover
    animation: none
    box-shadow: 0px 18px 22px rgba(0, 0, 0, 0.32)
    transform: translateY(-2px)
.menu-button
  font-size: 12px
  width: 100%
  margin: 0px
  .v-btn__content
    justify-content: flex-start
  &.menu-button--highlighted,
  &:hover
    background-color: rgba(74, 144, 226, 0.29) !important
  &:before
    background-color: transparent
</style>
