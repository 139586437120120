import { jwtDecode } from 'jwt-decode'

import { InspectorRootNodes, noDataTag } from '@/plugins/devtools/shared'

import type { Devtools } from '@/plugins/devtools/api'
import type { AnyObject } from '@policyfly/types/common'
import type { CustomInspectorNode } from '@vue/devtools-api'
import type { JwtPayload } from 'jwt-decode'

const rootNodeTitle = 'Session Data'

interface PFJwtPayload extends JwtPayload {
  ait: number
  exp: number
  is_staff: boolean
  is_superuser: boolean
  jti: string
  permissions_by_program_id: AnyObject
  user_id: number
}

function secondsToDate (seconds: number | undefined): string | undefined {
  return seconds
    ? new Date(seconds * 1000).toISOString()
    : undefined
}

export function setupSessionNode (devtools: Devtools): void {
  devtools.rootNodeGetters.push((payload) => {
    if (payload.filter && !rootNodeTitle.toLowerCase().includes(payload.filter.toLowerCase())) {
      return null
    }

    const node: Required<CustomInspectorNode> = {
      id: InspectorRootNodes.SESSION,
      label: rootNodeTitle,
      children: [],
      tags: [],
    }
    if (!devtools.stores.api?.refreshToken || !devtools.stores.api?.spaToken) {
      node.tags.push(noDataTag)
    }
    return node
  })

  devtools.stateGetters.push((payload) => {
    if (devtools.getRootNode(payload.nodeId) !== InspectorRootNodes.SESSION) return

    payload.state = {
      refresh: [
        {
          key: 'token',
          editable: false,
          value: '(No Token)',
        },
      ],
      access: [
        {
          key: 'token',
          editable: false,
          value: '(No Token)',
        },
      ],
    }

    const apiStore = devtools.stores.api
    if (!apiStore) return

    const decodedToken = (() => {
      try {
        if (!apiStore.refreshToken) return null
        return jwtDecode<PFJwtPayload>(apiStore.refreshToken)
      } catch {
        return null
      }
    })()
    if (decodedToken) {
      payload.state.refresh = [
        {
          key: 'token',
          editable: false,
          value: apiStore.refreshToken,
        },
        {
          key: 'jti',
          editable: false,
          value: decodedToken.jti,
        },
        {
          key: 'issued',
          editable: false,
          value: secondsToDate(decodedToken.iat),
        },
        {
          key: 'expiration',
          editable: false,
          value: secondsToDate(decodedToken.exp),
        },
        {
          key: 'userId',
          editable: false,
          value: decodedToken.user_id,
        },
        {
          key: 'isStaff',
          editable: false,
          value: decodedToken.is_staff,
        },
        {
          key: 'isSuperuser',
          editable: false,
          value: decodedToken.is_superuser,
        },
        {
          key: 'permissions',
          editable: false,
          value: decodedToken.permissions_by_program_id,
        },
      ]
    }

    if (apiStore.spaToken) {
      payload.state.access = [
        {
          key: 'token',
          editable: false,
          value: apiStore.spaToken,
        },
        {
          key: 'expiration',
          editable: false,
          value: apiStore.spaTokenExpiration,
        },
      ]
    }
  })
}
