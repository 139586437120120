import { pinia } from '@/plugins/pinia'

import type { Devtools } from '@/plugins/devtools/api'
import type { useApiStore } from '@/stores/api'
import type { useAppContextStore } from '@/stores/appContext'
import type { useFormBinderStore } from '@/stores/form/binder'
import type { useFormIssueStore } from '@/stores/form/issue'
import type { TSFixMe } from '@policyfly/types/common'
import type { PiniaPlugin } from 'pinia'

declare module '@/plugins/devtools/api' {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  interface Devtools {
    /**
     * @internal
     * A map of stores needed for the devtools plugin.
     * These are registered lazily so may not be defined when the devtools first opens.
     */
    stores: Partial<{
      api: ReturnType<typeof useApiStore>
      appContext: ReturnType<typeof useAppContextStore>
      formBinder: ReturnType<typeof useFormBinderStore>
      formIssue: ReturnType<typeof useFormIssueStore>
    }>
  }
}

/**
 * Creates and registers a {@link PiniaPlugin} that allows devtools to access specific stores directly.
 * Will also update the inspector whenever changes occur in those stores.
 */
export function setupPinia (devtools: Devtools): void {
  const plugin: PiniaPlugin = ({ store }) => {
    switch (store.$id) {
      case 'api':
      case 'appContext':
      case 'formBinder':
      case 'formIssue':
        devtools.stores[store.$id] = store as TSFixMe
        store.$subscribe(() => {
          devtools.refresh()
        })
    }
  }
  pinia.use(plugin)
}
